.file-field-override {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    
    &[readonly] {
        @extend .file-field-override;
        background-color: #fff;
    }
}
.logo-file-picker-container {
    position: relative;
}

.fileSelectorButton {
    position: absolute;
    top: 2px;
    left: 2px;
    bottom: 2px;
    padding: 7px 10px 6px 10px !important;
}

.text-required {
    color: $brand-danger;
}

.text-bold {
    font-weight: bold;
}