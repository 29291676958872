/*--------------------
Shared Variables
--------------------*/

$small-screen: 476px;
$grey: #111;
$blue: #5db6e8;
$darkBlue: #282c37;
$red: #E53A40;
$green: #8CD790;
$white: #fff;
$radius: 4px;
$bouncy: cubic-bezier(.20, 1.3, .7, 1);

/*--------------------
General
--------------------*/

.payment-card-form-container {
  /*--------------------
  General
  --------------------*/

  header {
    z-index: 2;
    transform: translate(0, 88px);
    transition: all .5s ease;
    &.header-slide {
      transform: translate(0, 0);
    }
    h1 {
      font-size: 20px;
    }
  }

  @media (max-width: $small-screen) {
    border: none;
  }

  .cardinfo-wrapper {
    display: flex;
    justify-content: space-around;
  }

  .bg-illustration {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 0;
    svg {
      width: 100%;
    }
  }

  .card-shape {
    border-radius: 6px;
    padding: 32px 32px 16px;

    @media (max-width: $small-screen) {
      padding: 32px 24px 16px;
    }
  }

  // Form wrapper styling
  #payment-card-form {
    padding: 100px 20px 10px;
    width: 100%;
    margin-bottom: 32px;
    transition: all 600ms $bouncy;
    animation: cardIntro 500ms $bouncy;
    z-index: 1;

    @media (max-width: $small-screen) {
      box-sizing: border-box;
      padding: 112px 32px 32px;
      width: 100%;
    }
    // Change background color based on the card type
    &.visa {
      @extend .card-shape;
      color: $white;
      background-color: #0D4AA2;
    }
    &.master-card {
      @extend .card-shape;
      color: $white;
      background-color: #363636;
      background: linear-gradient(115deg,#d82332, #d82332 50%, #f1ad3d 50%, #f1ad3d);
    }
    &.maestro {
      @extend .card-shape;
      color: $white;
      background-color: #363636;
      background: linear-gradient(115deg,#009ddd, #009ddd 50%, #ed1c2e 50%, #ed1c2e);
    }
    &.american-express {
      @extend .card-shape;
      color: $white;
      background-color: #007CC3;
    }
    &.discover {
      @extend .card-shape;
      color: $white;
      background-color: #ff6000;
      background: linear-gradient(#d14310, #f7961e);
    }
    &.unionpay, &.jcb, &.diners-club {
      @extend .card-shape;
      color: $white;
      background-color: #363636;
    }
  }

  .cardinfo-label {
    display: block;
    margin-bottom: 8px;
    text-transform: uppercase;
    @media (max-width: $small-screen) {
      font-size: 14px;
    }
  }

  .cardinfo-exp-date {
    margin-right: 16px;
    width: 100%;
    @media (max-width: 550px) {
      margin-right: 0px;
    }
  }

  .cardinfo-cvv {
    width: 100%;
  }

  #payment-card-form-submit {
    transform: translateY(-20px);
    transition: all 500ms $bouncy;
    opacity: 0;
    -webkit-appearance: none;
    &:disabled {
      cursor: default;
    }
    &:active {
      animation: cardIntro 200ms $bouncy;
    }
    &.show-button {
      transform: translateY(0);
      opacity: 1;
    }
  }

  // Card type image styles
  .cardinfo-card-number {
    position: relative;

    input {
        line-height: 1;
    }
  }

  #card-image {
    position: absolute;
    top: 32px;
    @media (max-width: $small-screen) {
      top: 0;
    }
    right: 16px;
    width: 44px;
    height: 28px;
    background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/346994/card_sprite.png);
    background-size: 86px 458px;
    border-radius: $radius;
    background-position: -100px 0;
    background-repeat: no-repeat;
    margin-bottom: 16px;
    &.visa {
      background-position: 0 -398px;
    }
    &.master-card {
      background-position: 0 -281px;
    }
    &.american-express {
      background-position: 0 -370px;
    }
    &.discover {
      background-position: 0 -163px;
    }
    &.maestro {
      background-position: 0 -251px;
    }
    &.jcb {
      background-position: 0 -221px;
    }
    &.diners-club {
      background-position: 0 -133px;
    }
  }

  /*--------------------
  Inputs
  --------------------*/

  // Styling for input wrappers, internal font styles are handled in javascript
  .input-wrapper {
    border-radius: 2px;
    background: rgba(255, 255, 255, 0.86);
    height: 44px;
    border: 1px solid #eee;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.06);
    padding: 5px 10px;
    margin-bottom: 16px;
  }

  .cardinfo-card-number,
  .cardinfo-exp-date,
  .cardinfo-cvv {
    transition: transform 0.3s;
  }

  // Change styles of the input wrappers using Braintree's provided classes.
  // Styles the wrapper of the focused input
  .braintree-hosted-fields-focused {
    border-color: $blue;
  }

  // Styles the wrapper of the invalid input
  .braintree-hosted-fields-invalid {
    border-color: $red;
    animation: shake 500ms $bouncy both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }

  /*--------------------
  Animations
  --------------------*/

  @keyframes cardIntro {
    0% {
      transform: scale(0.8) translate(0, 0);
      opacity: 0;
    }
    100% {
      transform: scale(1) translate(0, 0);
      opacity: 1;
    }
  }

  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
    20%,
    80% {
      transform: translate3d(1px, 0, 0);
    }
    30%,
    50%,
    70% {
      transform: translate3d(-3px, 0, 0);
    }
    40%,
    60% {
      transform: translate3d(3px, 0, 0);
    }
  }
}
