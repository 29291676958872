.keypad {
    max-width: $w-keypad;
    text-align: center;
    @include margin(0 auto);

    @media (max-height: $wp-keypad-sm) {
        max-width: $w-keypad-sm;

        ul {
            @include margin(null -15px);
        }
    }

    @media (max-height: $wp-keypad-xs) {
        ul {
            @include margin(null 10px);
        }
    }

    @media (max-height: $wp-keypad-sm) {
        .h1 {
            margin-bottom: 10px;
        }
    }

    &__code {
        border-bottom: 1px solid $body-bg;
        margin-bottom: 20px;

        @media (max-height: $wp-keypad-sm) {
            margin-bottom: 10px;
        }

        input {
            background-color: $bg-light;
            border: 0;
            color: $c-keypad-code;
            display: block;
            font-size: $fz-keypad-code;
            text-align: center;
            width: 100%;

            @media (max-height: $wp-keypad-xs) {
                font-size: $fz-keypad-code-xs;
            }
        }
    }

    &__key {
        @include padding(10px);

        @media (max-height: $wp-keypad-sm) {
            @include padding(5px);
        }

        .btn {
            border: 1px solid $body-bg;
            font-size: $fz-keypad-key;
            line-height: $h-keypad-key;
            border-radius: 50%;
            @include padding(0 5px);
            @include size($w-keypad-key, $h-keypad-key);

            @media (max-height: $wp-keypad-sm) {
                line-height: $h-keypad-key-sm;
                @include size($w-keypad-key-sm, $h-keypad-key-sm);
            }

            @media (max-height: $wp-keypad-xs) {
                font-size: $fz-keypad-key-xs;
                line-height: $h-keypad-key-xs;
                @include size($w-keypad-key-xs, $h-keypad-key-xs);
            }

            &:hover,
            &:active,
            &:active:hover,
            &:focus {
                border-color: $body-bg;
                background-color: $bg-light-hover;
                text-decoration: none;
            }
        }
    }

    &__action {

        &.btn {
            svg {
                margin-right: 0;
                @include margin(-7px 0 0 0);
                vertical-align: middle;
            }
        }

        &--cancel.btn svg {
            color: $c-keypad-action-cancel;
            @include size(auto, $h-keypad-action-cancel);

            @media (max-height: $wp-keypad-sm) {
                @include size(auto, $h-keypad-action-cancel-sm);
            }
        }

        &--backspace.btn svg {
            color: $c-keypad-action-backspace;
            @include size(auto, $h-keypad-action-backspace);

            @media (max-height: $wp-keypad-sm) {
                @include size(auto, $h-keypad-action-backspace-sm);
            }
        }
    }
}

#keypadPopup {
    .popup {
        &__content {
            bottom: auto;
            height: auto;
            max-height: 90%;
            min-width: 300px;
            right: auto;
            @include position(absolute, 50% null null 50%);
            @include transform(translateY(-50%) translateX(-50%));
        }
    }
}
