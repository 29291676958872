.pagination {
    border-radius: 0;

    > li {
        > a,
        > span {
            min-width: 38px;
            text-align: center;
            @include margin(0 -1px -1px 0);
        }

        &:first-child {
            > a,
            > span {
                @include border-left-radius(0);
            }
        }

        &:last-child {
            > a,
            > span {
                @include border-right-radius(0);
            }
        }
    }
}
