.select2 {
    width: 100% !important;

    &.select2-hidden-accessible {
        width: 1px !important;
    }
}
.select2-container {
    &--default {
        .select2-selection--single {
            border-color: $input-border;
            border-radius: $input-border-radius;
            height: auto;

            .select2-selection__rendered {
                color: $brand-primary;
                font-size: $font-size-large;
                line-height: $line-height-base;
                @include padding($padding-base-vertical 25px);
            }

            .select2-selection__arrow {
                content: '';
                background-image: url('#{$images}/svg/icon-arrow-bottom-blue.svg');
                background-position:  50% 50%;
                background-repeat: no-repeat;
                background-size: 17px 10px;
                margin-top: -5px;
                @include size(17px, 10px);
                @include position(absolute, 50% 25px null null);

                b {
                    display: none;
                }
            }
        }

        &.select2-container--open {
            .select2-selection--single {
                .select2-selection__arrow {
                    background-image: url('#{$images}/svg/icon-arrow-top-blue.svg');
                }
            }
        }

        .select2-results__option {
            font-size: $font-size-large;
            text-align: left;
            @include padding($padding-base-vertical 25px);

            &--highlighted[aria-selected] {
                background-color: $brand-primary;
            }
        }
    }

    .select2-dropdown {
        border-color: $input-border;
        overflow: hidden;
        margin-top: -1px;
        @include border-bottom-radius($input-border-radius);

        &.select2-dropdown--above {
            margin-top: 0;
            @include border-top-radius($input-border-radius);
            @include border-bottom-radius(0);
        }
    }
}
