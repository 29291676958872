.navbar-toggle {
    background-color: $btn-primary-bg;
    border-radius: 50%;
    border-color: $btn-primary-border;
    display: block;
    float: left;
    text-align: center;
    width: $h-navbar-toggle;
    @include margin(0 10px 0 0);
    @include padding(10px 0);

    &:hover,
    &:active,
    &:active:hover,
    &:focus {
        background-color: darken($brand-primary, 10%);
    }

    .icon-bar {
        background-color: $c-navbar-toggle-icon-bar;
        width: 16px;
        @include margin(null auto);

        + .icon-bar {
            margin-top: 3px;
        }
    }
}

.navbar-brand {
    height: auto;

    .auth & {
        display: inline-block;
        float: none;
        @include padding(60px null 55px null);

        @media (max-height: 660px) {
            @include padding(30px null 25px null);
        }

        @media (max-height: 580px) {
            @include padding(35px null 20px null);
        }

        @media (max-height: 535px) {
            @include padding(20px null 15px null);
        }

        svg {
            height: 185px;
            width: 203px;

            @media (max-height: 580px) {
                height: 145px;
                width: 175px;
            }

            @media (max-height: 535px) {
                height: 125px;
                width: 150px;
            }
        }
    }
}
