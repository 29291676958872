.system-picker {
    background-color: $bg-side-menu-light;

    &__item {
        position: relative;
        border-bottom: 1px solid $body-bg;
    }

    &__link {
        @extend .user-menu__link;
        background-color: $bg-side-menu-light;
        color: $c-side-menu-light;
        @include padding(20px 25px 20px 25px);

        @media (max-height: $h-media-md) {
            @include padding(15px null);
        }

        @media (max-height: $h-media-sm) {
            @include padding(10px null);
        }

        &:hover,
        &:focus {
            color: $c-side-menu-light;
            text-decoration: none;
            @include opacity(0.8);
        }

        &--no-icon {
            .system-picker__text {
                padding-left: 0;
                max-width: 100%;
            }
        }

        &--action {
            padding-right: 60px;
        }
    }

    &--link {
        .system-picker__link {
            &:after {
                content: '';
                background-image: url('#{$images}/svg/icon-arrow-bottom-blue.svg');
                background-position:  50% 50%;
                background-repeat: no-repeat;
                background-size: 17px 10px;
                margin-top: -5px;
                @include size(17px, 10px);
                @include position(absolute, 50% 25px null null);
            }

            &.opened:after {
                background-image: url('#{$images}/svg/icon-arrow-top-blue.svg');
            }
        }
    }

    &--add {
        //border-top: 1px solid $body-bg;
    }

    &__action {
        display: inline-block;
        margin-top: -23px;
        text-align: center;
        @include size(40px, 45px);
        @include padding(15px 0);
        @include position(absolute, 50% 15px null null);

        svg {
            @include size(15px, 15px);
        }

        &:hover,
        &:focus {
            @include opacity(0.6);
        }
    }

    &__text {
        @extend .user-menu__link-text;

        &--one-line {
            padding-bottom: 10px;
            padding-top: 7px;
        }
    }

    &__text-small {
        color: $c-system-picker-small;
        display: block;
        font-size: $fz-system-picker-small;
    }

    &__list {
        background-color: $bg-side-menu-light;
        //border-top: 1px solid $body-bg;
        display: none;
        position: absolute;
        width: 100%;
        z-index: ($z-side-menu + 1);
        //@include padding(13px null);

        .system-picker__item {
            border-bottom: 1px solid $body-bg;
        }
    }
}
