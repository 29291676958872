.pull-indicator {
    background-color: $brand-primary;
    border-radius: 50%;
    margin-left: (-$w-xpull / 2);
    text-align: center;
    top: -33px;
    transition: transform 300ms ease;
    z-index: $z-xpull;
    @include size($w-xpull, $w-xpull);
    @include position(absolute, 0 null null 50%);
    @include opacity(0.5);
    @include transform(translate(0px, 0) translate3d(0, 0, 0) rotate(0deg));

    &.arrow-rotate-active {
        @include opacity(1);
    }
}

.pull-spinner {
    background-image: url('#{$images}/svg/icon-refresh-white.svg');
    background-position: 50% 50%;
    background-size: ($w-xpull - 16) ($w-xpull - 16);
    background-repeat: no-repeat;
    border: 0;
    display: block;
    left: auto;
    @include size($w-xpull, $w-xpull);
    @include margin(0);
}
