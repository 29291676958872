h1, .h1 {
    color: $brand-primary;

    .header & {
        text-align: center;
        @include margin(6px 0 0 0);
        @include text-overflow();
    }
}

h2, .h2 {
    color: $brand-primary;
    @include margin(10px null 20px null);
    padding-left: $p-input-horizontal;
}
