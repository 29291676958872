.subscription {
  &--valid {
    color: #2ca02c;
  }
  &--overdue {
    color: #C20000;
  }
  &--cancelled {
    color: #d58512;
  }
  &--paymentError {
    color: #d58512;
  }
  &--cardExpired {
    color: #d58512;
  }
  &--insufficientFunds {
    color: #d58512;
  }
  &--suspended {
    color: #C20000;
  }
}