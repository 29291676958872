.user-menu {
    @include padding(40px 0 10px 0);

    @media (max-height: $h-media-md) {
        @include padding(0);
    }

    &__link {
        color: $c-side-menu;
        display: block;
        font-size: $fz-side-menu;
        line-height: 1.2;

        @media (max-height: $h-media-md) {
            font-size: $fz-side-menu-xs;
        }

        svg {
            margin-right: 15px;
            vertical-align: middle;
            @include size($h-side-menu-icon, $h-side-menu-icon);

            @media (max-height: $h-media-sm) {
                @include size($h-side-menu-icon-xs, $h-side-menu-icon-xs);
            }
        }
    }

    &__link-text {
        display: inline-block;
        max-width: 70%;
        vertical-align: middle;
    }
}
