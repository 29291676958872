.dashboard_add {
    &:before {
        position: absolute;
        content: "\f067";
        font-family: FontAwesome;
        font-size: $font-size-base;
        color: $brand-primary; 
        transform: translateX(-150%);
        -webkit-transform: translateX(-150%);
    }
}

.dashboard-list {
    list-style-type: none;
    padding: 0px;
    &.mobile {
        overflow: visible;
    }
    &.desktop {
        overflow: hidden;
    }
}

.dashboard-item {
    background-color: #FFF;
    height: 70px;
    border: $brand-primary solid 1px;
    border-radius: 20px 10px 10px 10px;
    padding: 20px 5px 10px 10px;
    margin-top: 10px;
    margin-left: 7px;
    margin-right: 8px;
    position: relative;
    
    &__content {
        padding-top:10px;
    }
    
    &:last-child {
        @extend .dashboard-item;
        margin-bottom: 9px;
    }
    
    &__header {
        width: 150px;
        height: 20px;
        font-size: $font-size-base;
        color: $brand-secondary;
        position: absolute;
        background: $brand-primary;
        left: 0px;
        top: -5px;
        border: $brand-primary solid 1px;
        border-radius: 20px 0px 20px 0px;
        text-align: center;
        overflow: hidden;
        padding-left: 10px;
        padding-right: 10px;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    
    &__loader {
        text-align: center;
        width: 10%;
        max-width: 50px;
        margin-left: 2px;
        margin-right: 2px;
        
        svg {
            @include size(16px, 16px);
            animation: spin 750ms linear infinite;
            fill: $brand-primary;
        }
    }
    
    &__error {
        text-align: center;
        width: 10%;
        max-width: 50px;
        margin-left: 2px;
        margin-right: 2px;
        
        svg {
            @include size(20px, 20px);
        }
    }
    
    &__name {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        > .list__sub-title {
            margin-top: 0px;
        }
    }
    
    &__button {
        transform: translateY(-25%);
    }
    
    &__icon {
        color: $brand-primary;
        min-width: 30px;
        > svg {
            width: 30px;
            height: 30px
        }
        width: 10%;
        max-width: 50px;
        transform: translateY(-15%);
        margin-left: 2px;
        margin-right: 2px;
    }
    
    &__preview {
        margin: 30px 50px 30px 50px;
        height: 60px;
    }
    
    &__delete {
        &-container {
            display: block;
            height: 47px;
            background-color: #fff;
            &.mobile {
                position: fixed;
                top: $h-header;
                left: 0px;
                right: 0px;
                z-index: 1;
            }
            &.desktop {
                position: absolute;
                z-index: 1;
            }
        }
        display: block;
        position: relative;
        left: 0px;
        right: 0px;
        background-color: $brand-danger;
        color: $brand-secondary;
        height: 47px;
        border: $brand-danger solid 1px;
        border-radius: 5px 5px 5px 5px;
        text-align: center;
        vertical-align: middle;
        font-size: 30px;
        &-info {
            border: dashed 1px #9e9e9e ;
            padding-top: 10px;
            padding-bottom: 10px;
            margin-top: 0px;
            color: #9e9e9e !important;
        }
    }
    
    &__leftAction {
        @extend .swipe-edit;
        
        top: 0px;
        left: -2px;
        transform: translateX(-100%);
        -moz-transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
        color: #000;
        background-color: #fff !important;
        border: solid 1px $brand-primary;
        
        > .fa-circle-thin {
            font-family: FontAwesome;
            font-size: 52px;
            vertical-align: middle;
            position: absolute;
            right: 11px;
            top: 8px;
            color: #006600;
        };
        > .fa-check {
            font-family: FontAwesome;
            font-size: 30px;
            vertical-align: middle;
            position: absolute;
            right: 16px;
            top: 19px;
            color: $brand-primary;
        };
        > #swipe-left-text {
            font-size: 18px;
            color: #000;
            position: absolute;
            right: 70px;
            top: 21px;
        };
    }
    &__rightAction {
        @extend .swipe-edit;
        
        top: 0px;
        right: -2px;
        transform: translateX(100%);
        -moz-transform: translateX(100%);
        -webkit-transform: translateX(100%);
        color: #000;
        background-color: #fff !important;
        border: solid 1px $brand-primary;
        
        > .fa-circle-thin {
            font-family: FontAwesome;            
            font-size: 52px;
            vertical-align: middle;
            position: absolute;
            left: 11px;
            top: 8px;
            color: #006600;
        };
        > .fa-check {
            font-family: FontAwesome;
            font-size: 30px;
            vertical-align: middle;
            position: absolute;
            left: 16px;
            top: 19px;
            color: $brand-primary;
        };
        > #swipe-right-text {
            font-size: 18px;
            color: #000;
            position: absolute;
            left: 70px;
            top: 21px;
        };
    }
}

.zone-status-text-inline {
    margin-left: 10px;
    color: $brand-primary;
}

.slip-reordering {
    box-shadow: 0 2px 10px 2px rgba(0,0,0,0.55);
}

.slip-swiping-container {
    overflow-x: hidden;
}

.slippylist li {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    cursor: default;
}

.swipe-edit {
    width: 50%;
    min-width: 220px;
    background-color: $brand-primary;
    font-size: 100%;
    position: absolute;
    color: $brand-secondary;
    display: block;
    height: 100%;
    &-name {
        @extend .swipe-edit;
        top: 0px;
        left: -2px;
        transform: translateX(-100%);
        -moz-transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
        > .fa-pencil {
            font-family: FontAwesome;
            font-size: 26px;
            height: 100%;
            vertical-align: middle;
            position: absolute;
            right: 21px;
            top: 21px;
            color: $brand-secondary;
            &:before {
                //position: absolute;
                //right: 0px;
            }
        };
        > .fa-circle-thin {
            font-family: FontAwesome;
            font-size: 52px;
            vertical-align: middle;
            position: absolute;
            right: 11px;
            top: 8px;
            color: $brand-secondary;
            &:before {
                //position: absolute;
                //right: 0px;
            }
        };
        > #swipe-left-text {
            font-size: 18px;
            color: $brand-secondary;
            position: absolute;
            right: 70px;
            top: 21px;
        };
    }
    &-colors {
        @extend .swipe-edit;
        top: 0px;
        right: -2px;
        transform: translateX(100%);
        -moz-transform: translateX(100%);
        -webkit-transform: translateX(100%);        
        > .fa-pencil {
            font-family: FontAwesome;
            font-size: 26px;
            height: 100%;
            vertical-align: middle;
            position: absolute;
            left: 23px;
            top: 21px;
            color: $brand-secondary;
            &:before {
                //position: absolute;
                //right: 0px;
            }
        };
        > .fa-circle-thin {
            font-family: FontAwesome;
            font-size: 52px;
            vertical-align: middle;
            position: absolute;
            left: 11px;
            top: 8px;
            color: $brand-secondary;
            &:before {
                //position: absolute;
                //right: 0px;
            }
        };
        > #swipe-right-text {
            font-size: 18px;
            color: $brand-secondary;
            position: absolute;
            left: 70px;
            top: 21px;
        };
    }
    border-radius: 10px 10px 10px 10px;
}

.edit_color_preview {
    display: table-cell;
    width: 150px;
    word-wrap: normal;
    text-align: center;
    vertical-align: middle;
}

.picker_text_container {
    display: table-cell;
}

.color_edit_container {
    display: inline-block;
    margin-bottom: 10px;
    border: 1px dashed;
}

.color_master_container {
    text-align: center;
}

.color_edit_preview_text {
    padding-top: 5px;
    text-align: center;
}

.swipe-ex {
    &-icon-container {
        display: inline-block;
        background-color: #fff;
        border-radius: 17px;
        width:34px;
        height:34px;
        margin-top:3px;
        text-align: center;
        padding-top: 2px;
        margin-left: 4px;
        margin-right: 4px;

        > svg {
            height: 30px
        }
    };
    
    &-icons-container {
        position: relative;
        background-color: $brand-primary;
        border-radius: 20px;
        width: 100%;
        height: 40px;        
        top:14px !important;
        &-left {
            @extend .swipe-ex-icons-container;
            text-align: right;
            right:10px !important;
            
            > .swipe-text-container {
                right: 42px;
            }
        }
        &-right {
            @extend .swipe-ex-icons-container;
            text-align: left;
            left:10px !important;
            
            > .swipe-text-container {
                left: 42px;
            }
        }
    }
}

.full-width {
    width: 100%;
}

.area-status-mini-button {
    cursor: pointer;
}

.swipe-text-container {
    color: $brand-secondary;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);    
}

.dashboard-empty {
    &__menu-text {
        margin-top: 40px;
        > svg {
            transform: translateY(25%);
            color: $brand-primary;
            height: 20px;
        }
    }
    
    &__arrow-down {
        margin-top: 20px;
        > svg {
            color: $brand-primary;
            height: 50px;
            width: 20px;
        }
    }
    
    &__fake-menu {
        margin-top: 20px;
        display: block;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }
    
    &__hand {
        position: relative;
        left: 55%;
        font-size: 22px;
        z-index: 6;
        color: #006600;
        transform: translateY(-50%);
        background-color: #fff;
    }
    
    &__sample-text {
        position: absolute;
        left: 14%;
        top: 40%;
        width: 100px;
        font-size: 16px;
        -webkit-transform: rotate(-55deg);
        -moz-transform: rotate(-55deg);
        -ms-transform: rotate(-55deg);
        -o-transform: rotate(-55deg);
        filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
        z-index: 5;
        color: #f00;
        text-shadow: 1px 1px #fff;
    }
}

.tooltip {
    z-index: 7;
}