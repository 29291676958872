.nav {
    .menu & {
        @include margin(0 -10px -20px);

        > li {
            float: left;
            padding-top: 50%;
            position: relative;
            width: 50%;

            > .area,
            > a {
                background-color: $bg-box;
                background-position:  75% 75%;
                background-repeat: no-repeat;
                background-size: auto 50%;
                border-radius: $border-radius-base;
                color: $c-box;
                font-size: $fz-box;
                font-weight: 400;
                @include box-shadow($bxsh-box);
                @include padding(15px 20px);
                @include position(absolute, 5px 10px 15px 10px);

                &:hover,
                &:active,
                &:active:hover,
                &:focus {
                    background-color: $bg-box-hover;
                }
            }

            > .area {
                height: auto;

                .area {
                    &__status {
                        @include padding(15px 20px);
                        @include position(absolute, 0 0 0 0);
                        @include size(100%, 100%);
                    }

                    &__menu-item {
                        font-size: $font-size-base;
                    }

                    &__status-name {
                        text-align: left;
                    }

                    &__name {
                        display: none;
                    }

                    &__status-icon {
                        @include position(absolute, 42% null null 40%);
                        @include size(50%, 50%);

                        svg {
                            margin-bottom: 0;
                            @include size(100%, 100%);
                        }
                    }
                }
            }

            &[class^="menu__item--"] {

                .menu-icon {
                    height: 50%;
                    position: absolute;
                    right: 20px;
                    bottom: 15px;
                    width: 75%;
                    text-align: right;

                    img {
                        max-height: 100%;
                    }
                }
                
                .menu-icon-android4 {
                    height: 5px;
                    position: absolute;
                    right: 20px;
                    bottom: 15px;
                    width: 5px;
                    text-align: right;
                    
                    img {
                        max-height: 100%;
                    }
                }
            }

            &.menu__item--zones > a {
                background-image: url('#{$images}/svg/icon_home-zones.svg');
            }

            &.menu__item--users > a {
                background-image: url('#{$images}/svg/icon_home-users.svg');
            }

            &.menu__item--settings > a {
                .menu-icon {
                    right: 0;
                }
            }
        }
    }

    .user-menu & {
        > li {
            > a {
                position: relative;
                @include padding(20px 50px 20px 25px);

                @media (max-height: $h-media-md) {
                    @include padding(15px null);
                }

                @media (max-height: $h-media-sm) {
                    @include padding(10px null);
                }

                &:hover,
                &:focus {
                    background-color: $c-brand-primary-hover;
                    color: $c-side-menu;
                }

                &:after {
                    content: '';
                    background-image: url('#{$images}/svg/icon-arrow-right-white.svg');
                    background-position:  50% 50%;
                    background-repeat: no-repeat;
                    background-size: 10px 17px;
                    margin-top: -9px;
                    @include size(10px, 17px);
                    @include position(absolute, 50% 30px null null);
                }
            }
        }
    }
}

.menu {
    &.list--areas {
        @include margin(0);
    }
}
