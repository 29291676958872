.user-block {
    @extend .user-menu__link;
    background-color: $bg-side-menu-light;
    border-bottom: 1px solid $body-bg;
    color: $c-side-menu-light;
    @include padding(30px 25px);

    @media (max-height: $h-media-md) {
        @include padding(15px null);
    }

    @media (max-height: $h-media-sm) {
        @include padding(10px null);
    }

    &__link-text {
        @extend .user-menu__link-text;
        max-width: 100%;

        a {
            &:hover,
            &:active,
            &:active:hover,
            &:focus {
                text-decoration: none;
            }
        }
    }

    &__text {
        @extend .user-menu__link-text;
        font-weight: bold;
        max-width: 100%;
    }
    
    &__side-logo svg {
        width: auto;
        height: auto;
    }
}
