@mixin aspect-ratio($width, $height) {
    position: relative;
    &:after {
        display: block;
        content: "";
        width: 100%;
        padding-top: ($height / $width) * 100%;
    }
    > .content_aspect {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}